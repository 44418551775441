import decode from 'jwt-decode';
import {
    useEffect
} from 'react';
import { useHistory } from 'react-router-dom';
import { store, useGlobalState } from 'state-pool';

import { podApi } from 'api/pod';
import { RegisterType } from 'types';
import { isValidJson } from 'utils';
import { getCurrentUser } from 'utils/auth';

import { resetState } from 'state';

store.setState("currentUser", null);

export const useAuth = (isRegister: boolean = false) => {
    const [currentUser, setCurrentUser] = useGlobalState(`currentUser`);
    const history = useHistory();
    // const userInterval = useRef<NodeJS.Timer>();

    useEffect(() => {
        if (!isRegister) {
            const persistedUser = localStorage.getItem('currentUser');

            if (persistedUser && isValidJson(persistedUser)) {
                const parsedPersistedUser = JSON.parse(persistedUser);

                // Here we ensure that we only update the state if the currentUser
                // state is actually different to avoid unnecessary re-renders.
                if (!currentUser || (currentUser.id !== parsedPersistedUser.id)) {
                    setCurrentUser(parsedPersistedUser);
                }
            }
        }
    }, [isRegister, currentUser, setCurrentUser]);

    async function getUserDetails(decodedJwt?: { id: number, firstname: string, lastname: string }) {

        try {
            podApi.get(`/user/viewuserinfo`).then(res => {
                if (typeof res === 'object') {
                    if (res.data) {
                        const cu = getCurrentUser();

                        let userData = decodedJwt ? { ...decodedJwt, ...res.data } : { ...cu, ...res.data };

                        setCurrentUser({ ...userData });

                        localStorage.setItem('currentUser', JSON.stringify({ ...userData }));

                        return true;
                    }
                }

                return false;
            });
        } catch (err) {
            console.log(err);

            return false;
        }
    }

    async function logout() {
        const accessToken = localStorage.getItem('access-token');
        const refreshToken = localStorage.getItem('refresh-token');

        if (refreshToken && typeof refreshToken === 'string' && accessToken && typeof accessToken === 'string') {
            await podApi.delete('/authO/logout', {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    token: refreshToken
                }
            });

            resetState();
            sessionStorage.clear();
             // First we check if the service worker registration track exists in localstorage, if so then we must keep it
            const lastUpdate = localStorage.getItem('lastUpdate');
            localStorage.clear();
            if(lastUpdate){
                // restore the lastUpdate value
                localStorage.setItem('lastUpdate', lastUpdate);
              } 
            if ('serviceWorker' in navigator) {
                const registrations = await navigator.serviceWorker.getRegistrations();
                registrations.forEach(async (registration) => {
                    await registration.unregister();
                });
            }
            document.cookie.split(";").forEach(function (cookie) {
                document.cookie = cookie
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            setCurrentUser(null);

            window.location.href = '/login';
        }
    }


    async function login(email: string, password: string) {

        try {
            const res = await podApi.post('/authO/login', {
                email,
                password
            });

            // success case
            if (res && res.status === 200 && res.data.accessToken) {
                const { accessToken, refreshToken } = res.data;

                localStorage.setItem('access-token', accessToken);
                localStorage.setItem('refresh-token', refreshToken);
                const decoded: { id: number, firstname: string, lastname: string, user_status: number } = decode(accessToken);

                setCurrentUser(decoded);
                localStorage.setItem('currentUser', JSON.stringify(decoded));

                if (decoded) {
                    await getUserDetails(decoded);
                    //280823Issues
                    // userInterval.current = setInterval(() => {
                    //     getUserDetails(decoded);
                    // }, 15000);

                    // if(decoded.user_status === 2) {history.push('/renewal'); return;}
                    return true;
                } else {
                    return true;
                };

            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    async function register(body: RegisterType) {
        podApi.post('/user/adduser', {
            "username": body.email,
            "name_first": body.firstname,
            "name_last": body.lastname,
            "email": body.email,
            "password": body.password,
            "confirm_password": body.confirmPassword,
        }).then((res: any) => {
            if (res.status === 200) {

                if (res && res.data.accessToken && res.data.refreshToken) {
                    const { accessToken, refreshToken } = res.data;

                    localStorage.setItem('access-token', accessToken);
                    localStorage.setItem('refresh-token', refreshToken);
                    const decoded: { id: number, firstname: string, lastname: string, user_status: number } = decode(accessToken);

                    setCurrentUser(decoded);
                    localStorage.setItem('currentUser', JSON.stringify({ ...decoded }));

                    history.push('/new-book/general-info');
                }

                // error check
                if (res && res.data.description && res.data.statusCode) {
                    const { statusCode } = res.data;

                    if (statusCode === "U30") {
                        history.push('/register/email-password?e=email');
                    }
                }

                return;
            }
        });
    }

    async function verify(id: number, token: string) {

        try {
            const res = await podApi.get(`/user/verifyaccount/${id}/${token}`);

            // success case
            if (res && res.status === 200 && (res.data.statusCode === 0)) {
                return res.data;
            } else {
                return res;
            }
        } catch (err) {
            return false;
        }
    }

    function refreshUserDetails() {
        try {
            getUserDetails();
        } catch (err) {
            console.log(err);
        }
    }

    return {
        logout,
        login,
        register,
        currentUser,
        getUserDetails,
        refreshUserDetails,
        verify
    }
}


