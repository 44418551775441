/**
 * Clears site data including cookies, local and session storage, IndexedDB, cache storage, and service workers.
 */
export async function clearSiteData() {
  const currentDate = new Date();
  const options = {
    timeZone: 'Africa/Johannesburg'
  };

  const timestampZA = currentDate.toLocaleString('en-ZA', options);
  const logArray = [];

  /**
   * Prints log messages with formatting.
   * @param {boolean} success - Indicates if the operation was successful.
   * @param {string} message - The log message.
   * @param {Date} [timestamp=currentDate] - The timestamp for the log message.
   */
  function printLogMessage(success, message, timestamp = timestampZA) {
    const status = success ? '✅' : '❌';
    console.log(`${status} ${message}  ${timestamp}`);
    logArray.push(`${status} ${message}  ${timestamp}`);
  }

  console.log(`Site Cleanup  ${currentDate.toUTCString()}`);

  try {
    // Clear Cookies
    document.cookie.split(';').forEach((c) => {
      document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    printLogMessage(true, 'Cookies cleared successfully');
  } catch (error) {
    printLogMessage(false, 'Cookies Failed to clear');
  }

  try {
    // Clear Local and Session Storage
    // First we check if the service worker registration track exists in localstorage, if so then we must keep it
    const lastUpdate = localStorage.getItem('lastUpdate');
    // clear local storage
    localStorage.clear();
    if(lastUpdate){
      // restore the lastUpdate value
      localStorage.setItem('lastUpdate', lastUpdate);
    } 
    sessionStorage.clear();
    printLogMessage(true, 'Local and Session Storage cleared successfully');
  } catch (error) {
    printLogMessage(false, 'Local and Session Storage Failed to clear');
  }

  try {
    // Clear IndexedDB
    const dbs = await window.indexedDB.databases();
    dbs.map((db) => {
      var DBDeleteRequest = window.indexedDB.deleteDatabase(db.name);
      DBDeleteRequest.onsuccess = function () {
        printLogMessage(true, `Database ${db.name} deleted successfully`);
      };
      DBDeleteRequest.onerror = function (event) {
        printLogMessage(false, `Error deleting database ${db.name}`);
      };
    });
  } catch (error) {
    printLogMessage(false, 'IndexedDB Failed to clear');
  }

  try {
    // Clear Cache Storage
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((name) => caches.delete(name)));
    printLogMessage(true, 'Cache Storage cleared successfully');
  } catch (error) {
    printLogMessage(false, 'Cache Storage Failed to clear');
  }

  try {
    // Unregister Service Workers
    const registrations = await navigator.serviceWorker.getRegistrations();

    for (const reg of registrations) {
      try {
        await reg.unregister();
        printLogMessage(true, `Service Worker unregistered successfully for ${reg.scope}`);
      } catch (error) {
        printLogMessage(false, `Service Worker failed to unregister for ${reg.scope}`);
      }
    }
  } catch (error) {
    printLogMessage(false, 'Error fetching service worker registrations');
  }

  printLogMessage(true, 'Site data cleared successfully');
}