import { useEffect, useState } from 'react';

export const UpdateController = () => {

    const [show, setShow] = useState(false);

 
    useEffect(() => {
        // We need to control looping of the Alert popup caused by the 'show' state. If the alert has been
        // shown then we do not let it trigger again until 1 hour later if it has to shown
        const handleUpdateListener = () => {
            const lastUpdate = localStorage.getItem('lastUpdate');
        
            if (!lastUpdate) {
                // No value in localStorage, so this is the first time
                localStorage.setItem('lastUpdate', Date.now().toString());
                setShow(true);
            } else {
                // Compare the last update time as numbers
                const lastUpdateTime = parseInt(lastUpdate, 10);
                const nowTime = Date.now(); 
                const diff = nowTime - lastUpdateTime; 
        
                // More than one hour ago, the pop-up can be displayed
                if (diff > 3600000) {
                    setShow(true);
                    // Reset Update the time
                    localStorage.setItem('lastUpdate', Date.now().toString());
                }
            }
        };
        
        if (process.env.NODE_ENV === 'production') {

            window.addEventListener('update-available', handleUpdateListener);

            // Clean up the event listener to prevent memory leaks
            return () => {
                window.removeEventListener('update-available', handleUpdateListener);
            };
       }

    }, []);

    function refresh() {
        if(navigator && navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({
                type: 'MESSAGE_IDENTIFIER',
            });
        }
        window.location.reload();
    }

    return {
        show,
        refresh
    }
}

