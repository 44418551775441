import axios from 'axios';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', async () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        await checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // Is not localhost. Just register service worker
        await registerValidSW(swUrl, config);
      }
    });
  }
}

async function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );

              const updateAvailableEvent = new Event('update-available');
              window.dispatchEvent(updateAvailableEvent);

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };

      // Push
      init(registration);
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

async function checkValidServiceWorker(swUrl: string, config?: Config) {
  try {
    // Fetch the service worker script
    const response = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    });

    // Ensure the service worker exists and is a JavaScript file
    const contentType = response.headers.get('content-type');
    if (
      response.status === 404 ||
      (contentType != null && contentType.indexOf('javascript') === -1)
    ) {
      // No service worker found. Probably a different app. Unregister and reload the page.
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
      window.location.reload(); // Reload after unregistering
    } else {
      // Service worker found, proceed as normal
      await registerValidSW(swUrl, config); // Properly await registerValidSW
    }
  } catch (error) {
    console.log('No internet connection found. App is running in offline mode.');
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

// -----------------------PUSH NOTIFICATIONS-----------------------------
// TODO seperate push functions to its own file

// TODO store in env file
const applicationServerPublicKey = 'BLno_uYF0FR_23f5vgU8mr2Q61E24p9Bu6rfF-hdScybXpRkPmnElO9-RbzxV-rLmdEbkSYqBIgo2wldN7pqLKE';

function init(registration: any) {
  subscribeUser(registration);

  // Set the initial subscription value
  registration.pushManager.getSubscription()
  .then(function (subscription: any) {
      updateSubscriptionOnServer(subscription);

      checkPermissions();
  });
};

function subscribeUser(registration: any) {

  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);

  registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerKey

  })
  .then(function (subscription: any) {
      console.log('User is subscribed.');

      updateSubscriptionOnServer(subscription);
      checkPermissions();
  })
  .catch(function (err: any) {
      console.log('Failed to subscribe the user: ', err);
      checkPermissions();
  });
};

function updateSubscriptionOnServer(subscription: any) {

  if (subscription) {
      axios.post('https://fierce-waters-11286.herokuapp.com/v1/pushnotification/push', {
          "end": subscription
      })
      .then(data => {
          console.log(data); // JSON data parsed by `data.json()` call
      });

  }
};

function checkPermissions() {
  if (Notification.permission === 'denied') {
      updateSubscriptionOnServer(null);
      return;
  }
};

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};